import React from 'react'
import { Card, Container,Row } from 'react-bootstrap'

import { isMobile } from 'react-device-detect';

export default function Cardhome(props) {

    const {myExperiences,handleDisplayExp} = props;

  return (
    <>
    {myExperiences && myExperiences.map((crd,crdID) => (
        isMobile ? 
        <Card className="cv-card-open p-2" key={crdID} onClick={() => handleDisplayExp(crd)}>
            <Card.Body>
                <Container>
                    <Row xs='auto'>
                        <div className="jumbotron  p-2"> 
                        <h3 className="cv-body-title-h3">{crd.title}</h3>
                        </div>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
        :
        <Card className="cv-card  p-2" key={crdID}  onClick={() => handleDisplayExp(crd)}>
            <Card.Body>
                <Container className="w-100 h-100 justify-content-center p-3">
                    <Row xs='auto'>
                        <div className="jumbotron p-2 "> 
                        <h3 className="cv-body-title-h3">{crd.title}</h3>
                        </div>
                       
                    </Row>
                </Container>
            </Card.Body>
        </Card>

    ))}
    </>
  )
}
