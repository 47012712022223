import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Cardhome from '../components/Cardhome'
import Presentation from '../components/Presentation';
import Backgroundpage from './Backgroundpage';

export default function Homepage(props) {

  const {myExperiences,handleDisplayExp,myCertifications} = props;


  return (
    <Container fluid className="w-100 h-100 p-1 cv-body-bg">
      {/* Presentation */}
      <Row xs='auto' className="d-flex justify-content-center align-items-center cv-border-bottom mb-5">
        <Presentation />
      </Row>
      {/* Experiences */}
      <Row xs='auto' className="cv-body-title px-5">
        <h3 className="text-left mb-3 cv-border-bottom"> My experiences</h3>
      </Row>
      <Row xs='auto' className="d-flex justify-content-center align-items-center mt-5 cv-border-bottom">
          <Cardhome myExperiences={myExperiences} handleDisplayExp={handleDisplayExp}/>
      </Row>
      {/* Background */}
      <Row xs='auto' className="d-flex justify-content-center align-items-center mt-5 cv-border-bottom">
          <Backgroundpage myCertifications={myCertifications} />
      </Row>
    </Container>
  )
}
