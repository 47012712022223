import React from 'react'
import { Container,Row } from 'react-bootstrap'
import Cardhome from '../components/Cardhome';

export default function Experiencespage(props) {

  const { myExperiences, handleDisplayExp} = props;
  
  return (
    <Container fluid className="w-100 h-100 cv-body-bg p-5" style={{height:"100%"}}>
      <Row xs='auto' lg='auto' className="cv-body-title  p-5 mb-5">
        <h3 className="text-left mb-3 border-bottom"> My experiences</h3>
      </Row>
        <Row xs='auto' className="d-flex justify-content-center align-items-center p-5 mb-5">
          <Cardhome myExperiences={myExperiences} handleDisplayExp={handleDisplayExp}/>
      </Row>
    </Container>
  )
}
