import React from 'react'
import { Col,Image } from 'react-bootstrap'
import myPicture from '../assets/YH8A1883 copie.jpg'

export default function Presentation() {
  
  return (
    <>
    {myPicture && 
    <><Col xs='12' md="3" lg="3" xl="3" xxl="3">
          <div className="jumbotron p-5 text-center">
            <h1 className="cv-body-title">Cyprien Lépine</h1>
            <h2 className="cv-body-title-h2"> Planisware specialist at TPG</h2>
          </div>
        </Col><Col xs='12' md="4" lg="4" xl="4" xxl="4" className="text-center">
            <div className="jumbotron p-5 text-center">
              <Image src={myPicture} alt="www.clemjl.com, Consultant Planisware Specialist Expert Project Management" width={300} roundedCircle className="justify-content-center" />
            </div>
          </Col><Col xs='12' md="5" lg="5" xl="5" xxl="5">
            <div className="jumbotron p-5 ">
              <h1 className="cv-body-title">Presentation</h1>
              <p className="cv-body-title-h2">After a degree in management and information systems, I discovered the technical and functional environment
                during various internships which unlocked a real passion for business and technical subjects. I aim to orientate my profile towards project management,
                mixing human resources, management and negotiation in an IT environment.</p>
            </div>
          </Col></>
          }
          </>
  )
}
