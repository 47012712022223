import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function Wrongpage() {

    const navigate = useNavigate();

    useEffect(()=> {
        navigate('/')
    },[navigate])
  return (
    <Container className="w-100 h-100">
        <Row className="justify-content-center">
            <div><a href="/">You're in the wrong page</a></div>
        </Row>
    </Container>
  )
}
