// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getDatabase,ref,child} from 'firebase/database'
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA7eul0MIWcaN7feeFZjG7EL5zM-GTbKrk",
  authDomain: "cyprienlepine-8db26.firebaseapp.com",
  databaseURL: "https://cyprienlepine-8db26-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cyprienlepine-8db26",
  storageBucket: "cyprienlepine-8db26.appspot.com",
  messagingSenderId: "1022147064249",
  appId: "1:1022147064249:web:5fdcc020d16f91810d0668",
  measurementId: "G-JDBW5GR0SK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const database = ref(getDatabase())
const dbexperience = child(database,'/experiences/');
const dbcertifications = child(database,"/certifications/");
export {database,dbexperience,dbcertifications};