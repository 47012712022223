import './App.css';
import { Routes,Route, useNavigate } from 'react-router-dom';
import React,{useEffect,useState} from 'react';
//Pages
import Homepage from './pages/Homepage';
import Headerpage from './pages/Headerpage';
import Backgroundpage from './pages/Backgroundpage';
import Experiencespage from './pages/Experiencespage';

//Database
import { onValue } from 'firebase/database';
import {dbexperience,dbcertifications } from "./firebase/firebase-config"
import Experiencesdetailpage from './pages/Experiencesdetailpage';
import Wrongpage from './pages/Wrongpage';

//Picture
import MyPicture from './assets/YH8A1883 copie.jpg'

function App() {

  const [myExperiences,setMyExperiences] = useState(null)
  const [myCertifications,setMyCertifications] = useState(null)
  useEffect(()=> {
    !myExperiences &&
    onValue(dbexperience,(snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val()
        records.push(data)
      })
      setMyExperiences(records)
    })
    !myCertifications && 
    onValue(dbcertifications,(snapshotS) => {
      let records2 = [];
      snapshotS.forEach(childSnapshot2 => {
        let data = childSnapshot2.val()
        records2.push(data)
      })
      setMyCertifications(records2)
    })
  },[myCertifications,myExperiences])

  //deal with experience/:id
  const navigate = useNavigate()
  const [currentObject,setCurrentObject] = useState(null)
  const handleDisplayExp = (obj) => {
    setCurrentObject([obj])
    navigate(`/cyprienlepine/consultant/experiences/${obj.Root}`)
  }
  //V cycle
  useEffect(()=> {
    !currentObject && 
    onValue(dbexperience, (snapshott) => {
      
      let records3 = [];
      snapshott.forEach(childSnapshot3 => {
        let path = `/cyprienlepine/consultant/experiences/${childSnapshot3.key}`
        if (window.location.pathname === path) {
          let data = childSnapshot3.val()
          records3.push(data)
        }
        
      })
      setCurrentObject(records3)
    })
  },[currentObject])

  useEffect(()=> {
    if (window.location.pathname === '/'){
      window.scroll(0,0)
    }
    else if (window.location.pathname === "/cyprienlepine/consultant/experiences") {
      window.scroll(0,0)
    }
  },[navigate])


  const handleDeplacement = (indic) => {
    let calculation;
    if (indic === '-'){
      if (currentObject[0].NumberId <= 1){
        console.log("can't go further")
      }
      else {
        calculation = currentObject[0].NumberId - 1
      }
      
    }
    else {
      if (currentObject[0].NumberId >= 8){
        console.log("can't go further")
      }
      else {
        calculation = currentObject[0].NumberId + 1
      }
    }
    onValue(dbexperience, (snapshottt) => {
      let records4 = [];
      snapshottt.forEach(childSnapshot3 => {
          let data = childSnapshot3.val()
          if (calculation === data.NumberId) {
            records4.push(data)
            navigate(`/cyprienlepine/consultant/experiences/${childSnapshot3.key}`)
          }
      })
      setCurrentObject(records4)
      
  })
}

  return (
    <>
    {myExperiences && myCertifications && MyPicture &&
    <>
    <Headerpage />
    <Routes>

          <Route path="/" element={<Homepage myExperiences={myExperiences} handleDisplayExp={handleDisplayExp} myCertifications={myCertifications}/>} />
          <Route path="/cyprienlepine/consultant/experiences" element={<Experiencespage  myExperiences={myExperiences} handleDisplayExp={handleDisplayExp}/>} />
          {currentObject && 
          <Route path="/cyprienlepine/consultant/experiences/:id" element={<Experiencesdetailpage currentObject={currentObject} handleDeplacement={handleDeplacement}/>} />
          }
          <Route path="/cyprienlepine/consultant/background" element={<Backgroundpage myCertifications={myCertifications}/>} />
          <Route path="*" element={<Wrongpage />} />
        </Routes>
        </>
}</>
  );
}

export default App;
