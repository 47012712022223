import React from 'react'
import {Card, Container, Row} from 'react-bootstrap'

export default function Backgroundpage(props) {

  const {myCertifications} = props;

  
  return (
    <Container fluid className="w-100 h-100 cv-body-bg p-5">
      <Row xs='auto' lg='auto' className="cv-body-title">
        <h3 className="text-left mb-3 border-bottom">My background</h3>
      </Row>
        <Row xs='auto' className="justify-content-center align-items-center p-5 mb-5">
          {myCertifications.map((mc,mcID) => (
            <Card key={mcID} className="cv-card-without-pointer ">
              <Card.Body>
                <Container fluid>
                  <Row>
                    <div className=" text-center jumbotron p-1">
                      <h3 className="cv-border-bottom">{mc.poste}</h3>
                      <h4 className="cv-border-bottom">{mc.title}</h4>
                      <h5 className="cv-body-title-white">{mc.definition}</h5>
                    </div>
                  </Row>
                </Container>
              </Card.Body>

            </Card>
          ))}
      </Row>
    </Container>
  )
}
