import React,{useEffect} from 'react'
import { Container, Row,Col,Card } from 'react-bootstrap';
import Movebtn from '../components/Movebtn';

export default function Experiencesdetailpage(props) {
    const {currentObject,handleDeplacement} = props;

    useEffect(()=> {
        window.scroll(0,0)
    },[currentObject])
    
    
  return (
    <>
    {currentObject && currentObject.map((co,coID) => (
    <Container fluid className="w-100 h-100 p-3 cv-body-bg" key={coID}>
        <Row xs='auto' className="d-flex justify-content-center align-items-center cv-border-bottom mb-3">
        {currentObject && 
        <Movebtn currentObject={currentObject} handleDeplacement={handleDeplacement}/>
        }
        </Row>
        <Row  xs='auto' className="d-flex justify-content-center align-items-center cv-border-bottom mb-3">
        
            <Col>
            <div className="jumbotron text-center">
                <h1 className="cv-body-title">{co.poste}</h1>
                <h2 className="cv-body-title-h2">{co.title}</h2>
                <h2 className="cv-body-title-h2">{co.date}</h2>
            </div>
          </Col>
         
          </Row>
          <Row>
          <Col>
          <div className="jumbotron p-3 text-center">
            <h4 className="cv-body-title-h2 cv-border-for-h2">{co.definition}</h4>
          </div>
          </Col>
          </Row>
        
        {/* Tags */}
        <Row className="d-flex justify-content-center align-items-center cv-border-bottom mb-3">
            <Col xs='12'  md="4" lg="4" xl="4" xxl="4" className="text-center">
            {co.tags.map((tag,tagID)=> (
                <div className=" badge badge-pill cv-badge-bg mb-3" key={tagID}>{tag}</div>
            ))}
            </Col>
           </Row>
        <Row  xs='auto' className="d-flex justify-content-center align-items-center ">
            <Col xs='auto' className="text-center">
                <Card className="cv-card-without-pointer" >
                    <Card.Body>
                        <Container>
                            <Row xs='auto' >
                                <div className="jumbotron"> 
                                <p className="cv-body-title-h3 text-center">{co.missions[0].mission1}</p>
                                </div>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs='auto' className="text-center">
                <Card className="cv-card-without-pointer" >
                    <Card.Body>
                        <Container>
                            <Row xs='auto' >
                                <div className="jumbotron"> 
                                <p className="cv-body-title-h3 text-center">{co.missions[0].mission2}</p>
                                </div>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
            </Row>
        <Row xs='auto' className="d-flex justify-content-center align-items-center">
            <Col xs='auto' className="text-center">
                <Card className="cv-card-without-pointer" >
                    <Card.Body>
                        <Container>
                            <Row xs='auto' >
                                <div className="jumbotron"> 
                                <p className="cv-body-title-h3 text-center">{co.missions[0].mission3}</p>
                                </div>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col >
            {co.missions[0].mission4 !== "" &&
            <Col xs='auto' className="text-center">
                <Card className="cv-card-without-pointer" >
                    <Card.Body>
                        <Container>
                            <Row xs='auto' >
                                <div className="jumbotron"> 
                                <p className="cv-body-title-h3 text-center">{co.missions[0].mission4}</p>
                                </div>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
            
        }
        </Row>
        {co.missions[0].mission5 !== "" &&
            <Row xs='auto' className="d-flex justify-content-center align-items-center">
            <Col>
                <Card className="cv-card-without-pointer" >
                    <Card.Body>
                        <Container>
                            <Row xs='auto' >
                                <div className="jumbotron"> 
                                <p className="cv-body-title-h3 text-center">{co.missions[0].mission5}</p>
                                </div>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
            </Row>
            }
        <Row xs='auto' className="d-flex justify-content-center align-items-center cv-border-bottom mb-3">
            {currentObject && 
        <Movebtn currentObject={currentObject} handleDeplacement={handleDeplacement}/>
            }</Row>
                
    </Container>
    
    ))}
    </>
  )
}
