import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
// pictures
import Monogram from '../assets/favicon-32x32.png'

export default function Headerpage() {

  return (
    <Navbar className="cv-body-bg cv-border-bottom" expand="lg" >
      <Container fluid className="p-3 ">
        <Navbar.Brand href="/" className="cv-body-title"><img src={Monogram} alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ">            
            <Nav.Link href="/cyprienlepine/consultant/experiences" className="cv-body-title inline">Experiences</Nav.Link>
            <Nav.Link href="/cyprienlepine/consultant/background" className="cv-body-title inline">Background</Nav.Link>
            <Nav.Link href="https://www.linkedin.com/in/cyprienlepine/" alt="Cyprien Lépine linkedin" target="_blank" className="cv-body-title inlinein">Linkedin</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
