import React from 'react'
import { Col } from 'react-bootstrap'

export default function Movebtn(props) {
  
    const {currentObject,handleDeplacement} = props;

  return (
    <>
    <Col className="text-center">
            {currentObject[0].NumberId !== 1 &&
                <div className="cv-btn text-center" onClick={() => handleDeplacement('-')}>Before</div>
            }
            </Col>
            <Col className="text-center">
            {currentObject[0].NumberId !== 8 && 
                <div className="cv-btn text-center"  onClick={() => handleDeplacement('+')}>Next</div>
            }
            </Col>
            </>
  )
}
